import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "results-app-5e81d.firebaseapp.com",
    databaseURL: "https://results-app-5e81d-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "results-app-5e81d",
    storageBucket: "results-app-5e81d.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MessagingSenderId,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth }