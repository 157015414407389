import React, { useState, useEffect } from 'react';
import NavBar from '../navBar/navBar';
import { fetchResultsByYearTuteIndex } from '../../services/services';

export default function UpdateMarksProcess () {

  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(results.length / rowsPerPage);

  // Paginated results
  const paginatedResults = results.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    // Fetch the results data (replace with actual fetching logic)
    const fetchData = async () => {
      const fetchedResults = await fetchResultsByYearTuteIndex(); // Add necessary parameters
      setResults(fetchedResults);
    };
    fetchData();
  }, []);

    return (
        <div className="min-h-screen  flex flex-col items-center">
          <header className="w-full bg-green-100 py-4 px-6 flex justify-between items-center custom-shadow">
            <NavBar />
          </header>
    
    
          {/* Main Content */}
          <main className="flex flex-col items-center w-full mt-8 px-4 ">
          <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-14 font-segoe mt-4">Update Marks</h1>
    
              {/* Table */}
              <div className="bg-[#FFFFFF] p-6 rounded-[10px] w-[95%] h-[70vh] border custom-borderMarks mt-4">
              <div className="overflow-x-auto">
                <table className="bg-white border-collapse border border-gray-300 mt-4 mx-auto w-4/5">
                  <thead>
                    <tr className="bg-white">
                      <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10">Index number</th>
                      <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10">Name</th>
                      <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10">Tute no</th>
                      <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10">Marks</th>
                      <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10">Rank</th>
                    </tr>
                  </thead>
                  <tbody>
                  {paginatedResults.map((result, idx) => (
                  <tr key={idx} className="bg-white">
                    <td className="table-cell">{result.indexNumber}</td>
                    <td className="table-cell">{result.name}</td>
                    <td className="table-cell">{result.tuteNumber}</td>
                    <td className="table-cell">{result.marks}</td>
                    <td className="table-cell">{result.rank}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
    
              {/* Parent Container with Relative Positioning */}
              <div className="relative min-h-screen flex flex-col items-center">
  
                {/* Pagination */}
                <div className="flex justify-center mt-4 absolute  right-32">
            <button
              className={`px-2 py-1 border rounded-l border-gray-300 bg-white hover:bg-gray-100 text-sm ${currentPage === 1 ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              &lt;
            </button>
            {[...Array(totalPages)].map((_, pageIdx) => (
              <button
                key={pageIdx}
                className={`px-2 py-1 border border-gray-300 text-sm ${currentPage === pageIdx + 1 ? 'bg-green-600 text-white' : 'bg-white hover:bg-gray-100 text-gray-700'}`}
                onClick={() => handlePageChange(pageIdx + 1)}
              >
                {pageIdx + 1}
              </button>
            ))}
            <button
              className={`px-2 py-1 border rounded-r border-gray-300 bg-white hover:bg-gray-100 text-sm ${currentPage === totalPages ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              &gt;
            </button>
          </div>
                {/* Add Marks Button */}
                
               
                <div className="absolute top-16 right-4">
                <br />
                    <button
                    className="bg-green-600 text-white font-bold py-2 px-6 rounded hover:bg-green-700"
                    >
                    Update 
                    </button>
                </div>

                </div>

              </div>
              </main>
              
            </div>
            
            
      );
};


