import React from 'react';
import Search from './search';
import { useLocation } from 'react-router-dom';
import Footer from '../footer/footer';
import NavBar from './NavBar';

const Home = () => {
  const location = useLocation();
  
  // Check if the current path matches the href of each link
  const isActive = (path) => location.pathname === path;

  return (
    <div className="flex flex-col min-h-screen bg-custom-gradient">
      {/* Fixed Navigation Bar */}
      {/* <header className="fixed top-0 left-0 w-full bg-[#DCFCE76B] py-4 custom-shadow z-50">
        <nav className="container mx-auto flex flex-wrap justify-between items-center px-4">
          <div className="text-lg font-semibold"></div>
          <div className="space-x-4 sm:space-x-6 lg:space-x-8">
            <a
              href="/"
              className={`${
                isActive('/') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              Home
            </a>
            <a
              href="#about"
              className={`${
                isActive('#about') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              About us
            </a>
            <a
              href="ContactUs"
              className={`${
                isActive('/ContactUs') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              Contact us
            </a>
            <a
              href="#TimeTable"
              className={`${
                isActive('#TimeTable') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              Time Table
            </a>
            
          </div>
        </nav>
      </header> */}
      <NavBar />

      {/* Main Content Section */}
      <main className="flex-grow pt-16">
        <div className="relative">
          {/* Background Images */}
          <img
            src="home.png"
            alt="Chemistry Graphic"
            className="w-full h-auto object-cover"
          />
        </div>

        {/* Search Form Section */}
        <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-semibold mb-6 text-center sm:text-3xl md:text-4xl lg:text-5xl">
            Find your result!
          </h2>
          <div className="flex justify-center">
            <Search />
          </div>
        </div>
      </main>

      
    </div>
  );
};

export default Home;
