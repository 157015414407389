import { getDatabase, ref, push, get, set, remove, query, equalTo, orderByChild, update } from 'firebase/database';
import { app } from '../firebase/Firebase';

const database = getDatabase(app);

const yearsTutesRef = ref(database, `Tute_Years`);
const studentsRef = ref(database, `Students`);
const resultsRef = ref(database, 'Results');

async function fetchAllResults() {
    return get(resultsRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                //console.log(data);
                return data;
            } else {
                console.log("No data available");
                return null;
            }
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });
}


// an optimized version
async function fetchResultsByYearTuteIndex(indexNumber, year, tute) {
    try {
        const resultsQuery = query(resultsRef, orderByChild('year'), equalTo(year));
        const resultsSnapshot = await get(resultsQuery);

        if (resultsSnapshot.exists()) {
            const resultsData = resultsSnapshot.val();

            // Filter data based on year and tuteNumber
            let filteredResults = Object.values(resultsData).filter(result =>
                result.year === year && result.tuteNumber === tute
            );

            // If indexNumber is provided, filter by indexNumber as well
            if (indexNumber) {
                filteredResults = filteredResults.filter(result =>
                    result.indexNumber === indexNumber
                );
            }

            // Get student data for relevant indexNumbers
            const indexNumbers = filteredResults.map(result => result.indexNumber);
            const studentPromises = indexNumbers.map(indexNum =>
                get(query(studentsRef, orderByChild('indexNumber'), equalTo(indexNum)))
            );
            const studentSnapshots = await Promise.all(studentPromises);

            const studentsData = studentSnapshots.reduce((acc, snapshot) => {
                if (snapshot.exists()) {
                    acc = { ...acc, ...snapshot.val() }; // Accumulate student data
                }
                return acc;
            }, {});

            // Append student name and school to each result
            filteredResults = filteredResults.map(result => {
                const student = studentsData[result.indexNumber];
                return {
                    ...result,
                    name: student ? student.name : "Unknown",
                    school: student ? student.school : "Unknown"
                };
            });

            return filteredResults;
        } else {
            console.log("No data available");
            return [];
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
}
// async function fetchResultsByYearTuteIndex(indexNumber, year, tute) {
//     try {
//         //const snapshot = await get(resultsRef);
//         const [resultsSnapshot, studentsSnapshot] = await Promise.all([
//             get(resultsRef),
//             get(studentsRef) // Fetch all student data at once
//         ]);

//         if (resultsSnapshot.exists() && studentsSnapshot.exists()) {
//             //const data = snapshot.val();
//             const resultsData = resultsSnapshot.val();
//             const studentsData = studentsSnapshot.val();

//             // Debug: Log the raw data to ensure it’s being retrieved correctly
//             //console.log("Raw data:", data);

//             // Filter data based on year and tuteNumber
//             let filteredResults = Object.values(resultsData).filter(result =>
//                 result.year === year && result.tuteNumber === tute
//             );
//             //console.log("Filtered results:", filteredResults);

//             // If indexNumber is provided, filter by indexNumber as well
//             if (indexNumber) {
//                 filteredResults = filteredResults.filter(result =>
//                     result.indexNumber === indexNumber
//                 );
//             }

//             // Append student name and school to each result
//             filteredResults = filteredResults.map(result => {
//                 const student = studentsData[result.indexNumber];
//                 //const student = Object.values(studentsData).find(student => student.indexNumber === result.indexNumber);
//                 return {
//                     ...result,
//                     name: student ? student.name : "Unknown",
//                     school: student ? student.school : "Unknown"
//                 };
//             });

//             //console.log("Filtered results:", filteredResults);
//             return filteredResults;
//         } else {
//             console.log("No data available");
//             return [];
//         }
//     } catch (error) {
//         console.error("Error fetching data:", error);
//         return [];
//     }
// }


async function fetchSpecificResult(indexNumber, year, tute) {
    const resultQuery = query(resultsRef, orderByChild('indexNumber'), equalTo(indexNumber));
    return get(resultQuery)
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                // Filter the data to match the year and tuteNumber
                const relevantResults = Object.values(data).filter(
                    (result) => result.year === year && result.tuteNumber === tute
                );
                return relevantResults.length > 0 ? relevantResults[0] : null;
            } else {
                return null;  // No results for this indexNumber
            }
        })
        .catch((error) => {
            console.error("Error fetching results by year/tute/index:", error);
            return null;
        });
}


// an optimized version
async function fetchResultsForStudent(indexNumber, year, tute) {
    try {
        const resultsQuery = query(resultsRef, orderByChild('year'), equalTo(year));
        const resultsSnapshot = await get(resultsQuery);

        if (resultsSnapshot.exists()) {
            const resultsData = resultsSnapshot.val();

            let filteredResults = Object.values(resultsData).filter(result =>
                result.tuteNumber === tute
            );

            // Get relevant student data for the filtered results
            const indexNumbers = filteredResults.map(result => result.indexNumber);
            const studentPromises = indexNumbers.map(indexNum =>
                get(query(studentsRef, orderByChild('indexNumber'), equalTo(indexNum)))
            );
            const studentSnapshots = await Promise.all(studentPromises);

            const studentsData = studentSnapshots.reduce((acc, snapshot) => {
                if (snapshot.exists()) {
                    acc = { ...acc, ...snapshot.val() }; // Accumulate student data
                }
                return acc;
            }, {});

            // Append student name and school to each result
            filteredResults = filteredResults.map(result => {
                const student = studentsData[result.indexNumber];
                return {
                    ...result,
                    name: student ? student.name : "Unknown",
                    school: student ? student.school : "Unknown"
                };
            });

            // Find the specific student's result
            let searchedStudentResult = null;
            // If indexNumber is provided, filter by indexNumber as well
            if (indexNumber) {
                searchedStudentResult = filteredResults.filter(result =>
                    result.indexNumber === indexNumber
                );
            }

            // Sort the filtered results by rank or marks to get the top 10 results
            const top10Results = filteredResults
                .sort((a, b) => b.mark - a.mark) // Sort by mark in descending order
                .slice(0, 10); // Take the top 10 results

            // Return the searched student's result and the top 10 results
            return {
                searchedStudentResult,
                top10Results
            };
        } else {
            console.log("No data available");
            return [];
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
}
// async function fetchResultsForStudent(indexNumber, year, tute) {
//     try {
//         //const snapshot = await get(resultsRef);
//         const [resultsSnapshot, studentsSnapshot] = await Promise.all([
//             get(resultsRef),
//             get(studentsRef) // Fetch all student data at once
//         ]);

//         if (resultsSnapshot.exists() && studentsSnapshot.exists()) {
//             //const data = snapshot.val();
//             const resultsData = resultsSnapshot.val();
//             const studentsData = studentsSnapshot.val();

//             // Debug: Log the raw data to ensure it’s being retrieved correctly
//             //console.log("Raw data:", data);

//             // Filter data based on year and tuteNumber
//             let filteredResults = Object.values(resultsData).filter(result =>
//                 result.year === year && result.tuteNumber === tute
//             );
//             //console.log("Filtered results:", filteredResults);

//             // Append student name and school to each result
//             filteredResults = filteredResults.map(result => {
//                 const student = studentsData[result.indexNumber];
//                 return {
//                     ...result,
//                     name: student ? student.name : "Unknown",
//                     school: student ? student.school : "Unknown"
//                 };
//             });

//             // Find the specific student's result
//             let searchedStudentResult = null;
//             // If indexNumber is provided, filter by indexNumber as well
//             if (indexNumber) {
//                 searchedStudentResult = filteredResults.filter(result =>
//                     result.indexNumber === indexNumber
//                 );
//             }

//             // Sort the filtered results by rank or marks to get the top 10 results
//             const top10Results = filteredResults
//                 .sort((a, b) => b.mark - a.mark) // Sort by mark in descending order
//                 .slice(0, 10); // Take the top 10 results

//             // Return the searched student's result and the top 10 results
//             return {
//                 searchedStudentResult,
//                 top10Results
//             };
//         } else {
//             console.log("No data available");
//             return [];
//         }
//     } catch (error) {
//         console.error("Error fetching data:", error);
//         return [];
//     }
// }

async function addNewResults(newResults) {
    if (!newResults || !Array.isArray(newResults)) {
        console.log("Results set is invalid!");
        return;
    }

    const validResults = [];

    for (let result of newResults) {
        // Creating a composite key using `year`, `tuteNumber`, and `indexNumber`
        const compositeKey = `${result.year}_${result.tuteNumber}_${result.indexNumber}`;

        // Query the database to check for existing entry with the same composite key
        const resultQuery = query(
            ref(database, `Results/${compositeKey}`)
        );

        try {
            const snapshot = await get(resultQuery);
            if (snapshot.exists()) {
                console.log(`Duplicate found for year: ${result.year}, tuteNumber: ${result.tuteNumber}, indexNumber: ${result.indexNumber}. Skipping insertion.`);
                continue; // Skip this result
            }
            validResults.push(result);
        } catch (error) {
            console.error("Error checking for duplicates:", error);
            return; // Exit if there's an error checking for duplicates
        }
    }

    if (validResults.length === 0) {
        console.log("No valid results to add.");
        return;
    }

    // Step 2: Calculate ranks
    //const rankedResults = calculateRanks(validResults);

    const addedResultRefs = []; // To store references of successfully added results

    try {
        // Step 3: Add results with composite key
        const promises = /*rankedResults*/validResults.map(async (result) => {
            const compositeKey = `${result.year}_${result.tuteNumber}_${result.indexNumber}`;
            const newResultRef = await set(ref(database, `Results/${compositeKey}`), result);
            addedResultRefs.push(newResultRef);
        });

        await Promise.all(promises);
        console.log("All valid results saved and ranks updated.");

        // Step 4: Update ranks for all related results
        await updateRanksForYearAndTute(/*rankedResults*/validResults[0].year, /*rankedResults*/validResults[0].tuteNumber);

    } catch (error) {
        console.error('Error saving results:', error);

        // Step 5: Rollback previously added results
        try {
            const rollbackPromises = addedResultRefs.map(ref => remove(ref));
            await Promise.all(rollbackPromises);
            console.log("Rollback completed. All previously added results have been removed.");
        } catch (rollbackError) {
            console.error('Error during rollback:', rollbackError);
        }
    }
}


async function updateResult(resultId, stdName, updatedResult) {
    const resultRef = ref(database, `Results/${resultId}`);
    const { year, tuteNumber, indexNumber } = updatedResult;

    try {
        // Step 1: Update the specific result
        // await /*set*/update(resultRef, updatedResult);
        // console.log("Result updated successfully.");
        // Step 1: Check for existing results with the same year and indexNumber but different resultId
        const existingQuery = query(
            ref(database, 'Results'),
            orderByChild('year'),
            equalTo(year)
        );

        const earliySnapshot = await get(existingQuery);

        if (earliySnapshot.exists()) {
            const allResults = Object.entries(earliySnapshot.val()).map(([key, value]) => ({
                id: key,
                ...value,
            }));

            // Check if there's another result with the same year, indexNumber, and a different id
            const conflictingResult = allResults.find(result =>
                result.indexNumber === indexNumber && result.tuteNumber === tuteNumber && result.id !== resultId
            );

            if (conflictingResult) {
                console.log(`Conflict found: Another result exists for year ${year} and indexNumber ${indexNumber}. Update skipped.`);
                return { isSuccess: false, message: `Conflict found: Update skipped for year ${year}, indexNumber ${indexNumber}.` };
            }
        }

        // Step 1.5: Update the specific result
        /*await update(resultRef, updatedResult);
        console.log("Result updated successfully.");*/
        // Step 2: Create a new key if the indexNumber changes
        const oldCompositeKey = resultId;  // Assuming resultId is the old composite key
        const newCompositeKey = `${year}_${tuteNumber}_${indexNumber}`;

        if (oldCompositeKey !== newCompositeKey) {
            // Step 3: Create a new entry with the updated composite key
            await set(ref(database, `Results/${newCompositeKey}`), updatedResult);
            console.log("New result entry created with updated key.");

            // Step 4: Delete the old entry
            await remove(ref(database, `Results/${oldCompositeKey}`));
            console.log("Old result entry deleted.");

        } else {
            // If the composite key has not changed, just update the existing entry
            await update(ref(database, `Results/${oldCompositeKey}`), updatedResult);
            console.log("Result updated successfully.");
        }

        // Step 2: Fetch all results for the same year and tute
        const resultsQuery = query(ref(database, 'Results'),
            orderByChild('year'),
            equalTo(year)
        );
        const snapshot = await get(resultsQuery);

        if (snapshot.exists()) {
            const allResults = Object.entries(snapshot.val()).map(([key, value]) => ({
                id: key,
                ...value,
            })).filter(result => result.tuteNumber === tuteNumber);

            // Step 3: Recalculate ranks
            const updatedResults = calculateRanks(allResults);

            // Step 4: Update the ranks in the database
            const updatePromises = updatedResults.map(result =>
                /*set*/update(ref(database, `Results/${result.id}`), result)
            );

            await Promise.all(updatePromises);
            console.log("All ranks updated successfully.");
            return { isSuccess: true, message: "Result and all ranks updated successfully." };
        } else {
            console.log("No matching results found.");
            return { isSuccess: false, message: "No matching results found." };
        }
    } catch (error) {
        console.error('Error updating result and ranks:', error);
        return { isSuccess: false, message: error };
    }
}


async function getAllNewYearsAndTutes() {
    return get(yearsTutesRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                //console.log(data);
                return Object.values(data);
            } else {
                console.log("No data available");
                return [];
            }
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
            return null
        });
}


async function getAllStudents() {
    return get(studentsRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                //console.log(data);
                return Object.values(data);
            } else {
                console.log("No data available");
                return [];
            }
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
            return null
        });
}


async function checkIndexNumberExists(indexNumber) {
    const stdQuery = query(studentsRef, orderByChild('indexNumber'), equalTo(indexNumber));
    try {
        const snapshot = await get(stdQuery);
        return snapshot.exists();
    } catch (error) {
        console.error("Error checking index number:", error);
        return false;
    }
}


async function addNewStudent(studentData) {
    /*const stdQuery = query(
        studentsRef,
        orderByChild('indexNumber'),
        equalTo(studentData.indexNumber)
    );*/
    const stdRef = ref(database, `Students/${studentData.indexNumber}`); // Use indexNumber as the key

    try {
        const snapshot = await get(stdRef);//await get(stdQuery);

        // Check if the student exists
        if (snapshot.exists()) {
            console.log("This index number already exists.");
            return { isSuccess: false, message: "This index number already exists!" };
        }

        // If it doesn't exist, add the new student
        const newStudent = {
            indexNumber: studentData.indexNumber,
            name: studentData.name,
            school: studentData.school
        };

        await set(stdRef, newStudent); // Use set() instead of push() to define custom key
        //await push(ref(database, 'Students'), newStudent);
        console.log("Student added.");
        return { isSuccess: true, message: "Student added successfully." };
    } catch (error) {
        console.error('Error saving Student: ', error);
        return { isSuccess: false, message: error };
    }
}

async function updateStudent(existingIndexNumber, updatedStudentData) {
    const studentRef = ref(database, `Students/${existingIndexNumber}`);

    try {
        // Fetch the existing student data
        const snapshot = await get(studentRef);

        if (!snapshot.exists()) {
            console.log(`Student with index number ${existingIndexNumber} not found.`);
            return { isSuccess: false, message: `Student with index number ${existingIndexNumber} not found.` };
        }

        // If the indexNumber is being updated, check for conflicts
        if (existingIndexNumber !== updatedStudentData.indexNumber) {
            const checkNewIndexQuery = query(
                studentsRef,
                orderByChild('indexNumber'),
                equalTo(updatedStudentData.indexNumber)
            );

            const conflictSnapshot = await get(checkNewIndexQuery);

            if (conflictSnapshot.exists()) {
                console.log(`Another student with index number ${updatedStudentData.indexNumber} already exists.`);
                return { isSuccess: false, message: `Another student with index number ${updatedStudentData.indexNumber} already exists.` };
            }
        }

        // Update the student data
        const updatedRef = ref(database, `Students/${updatedStudentData.indexNumber}`);

        // If the index number is changing, need to remove the old entry and set the new one
        if (existingIndexNumber !== updatedStudentData.indexNumber) {
            await remove(studentRef); // Remove the old record
        }

        // Set the updated data
        await set(updatedRef, {
            indexNumber: updatedStudentData.indexNumber,
            name: updatedStudentData.name,
            school: updatedStudentData.school
        });

        console.log(`Student with index number ${existingIndexNumber} updated successfully.`);
        return { isSuccess: true, message: `Student with index number ${existingIndexNumber} updated successfully.` };
    } catch (error) {
        console.error('Error updating student:', error);
        return { isSuccess: false, message: error };
    }
}


async function deleteStudent(indexNumber) {
    const studentRef = ref(database, `Students/${indexNumber}`);

    try {
        const snapshot = await get(studentRef);

        if (snapshot.exists()) {
            await remove(studentRef);
            console.log(`Student with index number ${indexNumber} deleted successfully.`);
            return { isSuccess: true, message: `Student with index number ${indexNumber} deleted successfully.` };
        } else {
            console.log(`Student with index number ${indexNumber} not found.`);
            return { isSuccess: false, message: `Student with index number ${indexNumber} not found.` };
        }
    } catch (error) {
        console.error('Error deleting student:', error);
        return { isSuccess: false, message: error };
    }
}



async function addNewYearAndTute(yearTute) {
    const yearsTutesQuery = query(
        ref(database, 'Tute_Years'),
        orderByChild('year'),
        equalTo(yearTute.year)
    );

    try {
        const snapshot = await get(yearsTutesQuery);

        // Check if the year exists
        if (snapshot.exists()) {
            const existingTutes = snapshot.val();

            // Check if the tute already exists for this year
            const tuteExists = Object.values(existingTutes).some(
                entry => entry.tute === yearTute.tute
            );

            if (tuteExists) {
                console.log("This Year & Tute combination already exists.");
                return { isSuccess: false, message: "This Year & Tute combination already exists!" };
            }
        }

        // If it doesn't exist, add the new year and tute
        const newYearTute = {
            year: yearTute.year,
            tute: yearTute.tute
        };

        await push(ref(database, 'Tute_Years'), newYearTute);
        console.log("Year & Tute saved.");
        return { isSuccess: true, message: "Year & Tute saved." };
    } catch (error) {
        console.error('Error saving Year & Tute:', error);
        return { isSuccess: false, message: error };
    }
}


async function updateYearAndTute(existingYear, existingTute, updatedYearTute) {
    const yearTuteQuery = query(
        ref(database, 'Tute_Years'),
        orderByChild('year'),
        equalTo(existingYear)
    );

    try {
        // Fetch the existing year & tute data
        const snapshot = await get(yearTuteQuery);

        if (!snapshot.exists()) {
            console.log(`Year ${existingYear} with Tute ${existingTute} not found.`);
            return { isSuccess: false, message: `Year ${existingYear} with Tute ${existingTute} not found.` };
        }

        // Find the specific year & tute entry that we want to update
        const yearTuteData = snapshot.val();
        const keyToUpdate = Object.keys(yearTuteData).find(
            key => yearTuteData[key].tute === existingTute
        );

        if (!keyToUpdate) {
            console.log(`Tute ${existingTute} for Year ${existingYear} not found.`);
            return { isSuccess: false, message: `Tute ${existingTute} for Year ${existingYear} not found.` };
        }

        // If the year or tute is being updated, check for conflicts
        if (existingYear !== updatedYearTute.year || existingTute !== updatedYearTute.tute) {
            const checkConflictQuery = query(
                ref(database, 'Tute_Years'),
                orderByChild('year'),
                equalTo(updatedYearTute.year)
            );

            const conflictSnapshot = await get(checkConflictQuery);

            if (conflictSnapshot.exists()) {
                const conflictTuteExists = Object.values(conflictSnapshot.val()).some(
                    entry => entry.tute === updatedYearTute.tute
                );

                if (conflictTuteExists) {
                    console.log(`Year ${updatedYearTute.year} with Tute ${updatedYearTute.tute} already exists.`);
                    return { isSuccess: false, message: `Year ${updatedYearTute.year} with Tute ${updatedYearTute.tute} already exists.` };
                }
            }
        }

        // Update the year & tute
        const updatedRef = ref(database, `Tute_Years/${keyToUpdate}`);

        // If the year or tute is changing, update the entry
        await update(updatedRef, { year: updatedYearTute.year, tute: updatedYearTute.tute });

        console.log(`Year ${existingYear} and Tute ${existingTute} updated successfully.`);
        return { isSuccess: true, message: `Year ${existingYear} and Tute ${existingTute} updated successfully.` };
    } catch (error) {
        console.error('Error updating Year & Tute:', error);
        return { isSuccess: false, message: error };
    }
}


async function deleteYearAndTute(year, tute) {
    const yearsTutesQuery = query(
        ref(database, 'Tute_Years'),
        orderByChild('year'),
        equalTo(year)
    );

    try {
        const snapshot = await get(yearsTutesQuery);

        if (snapshot.exists()) {
            const yearTuteData = snapshot.val();

            // Find the specific year & tute combination to delete
            const keyToDelete = Object.keys(yearTuteData).find(
                key => yearTuteData[key].tute === tute
            );

            if (keyToDelete) {
                const deleteRef = ref(database, `Tute_Years/${keyToDelete}`);
                await remove(deleteRef);
                console.log(`Year ${year} and Tute ${tute} deleted successfully.`);
                return { isSuccess: true, message: `Year ${year} and Tute ${tute} deleted successfully.` };
            } else {
                console.log(`Tute ${tute} for Year ${year} not found.`);
                return { isSuccess: false, message: `Tute ${tute} for Year ${year} not found.` };
            }
        } else {
            console.log(`Year ${year} not found.`);
            return { isSuccess: false, message: `Year ${year} not found.` };
        }
    } catch (error) {
        console.error('Error deleting Year & Tute:', error);
        return { isSuccess: false, message: error };
    }
}



function calculateRanks(stdResults) {
    // Step 1: Sort the array by marks in descending order
    const orderedArray = [...stdResults].sort((a, b) => b.mark - a.mark);

    // Step 2: Assign ranks
    let currentRank = 1;

    orderedArray.forEach((result, index) => {
        if (index > 0 && result.mark < orderedArray[index - 1].mark) {
            // Only increment the rank if the current marks are less than the previous marks
            currentRank = index + 1;
        }
        result.rank = currentRank; // Assign rank
    });

    return orderedArray; // Return the array with ranks added
}


async function updateRanksForYearAndTute(year, tuteNumber) {
    const resultsQuery = query(
        ref(database, 'Results'),
        orderByChild('year'),
        equalTo(year)
    );

    try {
        const snapshot = await get(resultsQuery);
        if (snapshot.exists()) {
            const allResults = Object.entries(snapshot.val()).map(([key, value]) => ({
                id: key,
                ...value,
            })).filter(result => result.tuteNumber === tuteNumber);

            // Recalculate ranks
            const updatedResults = calculateRanks(allResults);

            // Update the ranks in the database
            const updatePromises = updatedResults.map(result =>
                update(ref(database, `Results/${result.id}`), result)
            );

            await Promise.all(updatePromises);
            console.log("All ranks updated successfully for the given year and tute.");
        } else {
            console.log("No results found for the given year and tute.");
        }
    } catch (error) {
        console.error('Error updating ranks:', error);
    }
}


export { addNewResults, updateResult, addNewStudent, updateStudent, deleteStudent, addNewYearAndTute, updateYearAndTute, deleteYearAndTute, fetchAllResults, fetchResultsForStudent, fetchResultsByYearTuteIndex, fetchSpecificResult, checkIndexNumberExists, getAllStudents, getAllNewYearsAndTutes };