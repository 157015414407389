import React, { useEffect, useState } from 'react';
import NavBar from '../navBar/navBar';
import { checkIndexNumberExists, fetchResultsByYearTuteIndex, fetchSpecificResult, getAllNewYearsAndTutes } from '../../services/services';
import { useNavigate } from 'react-router-dom';
import PopUpModal from './PopUpModal';

export default function AddMarks() {
  const navigate = useNavigate();
  const [fields, setFields] = useState([{ id: Date.now(), indexNumber: '', mark: '' }]);
  const [allYearsAndTutes, setAllYearsAndTutes] = useState([]);
  const [availableYears, setAvailableYears] = useState(new Set());
  const [relevantTutes, setRelevantTutes] = useState(new Set());
  const [year, setYear] = useState("");
  const [tuteNumber, setTute] = useState("");

  const [validIndexes, setValidIndexes] = useState({}); // Store validity of each indexNumber
  const [isDuplicate, setIsDuplicate] = useState({});
  const [stdCount, setStdCount] = useState(0);

  const [errorMessage, setErrorMessage] = useState({ general: '', fields: [] });
  const [showModal, setShowModal] = useState(false);  // State to control modal visibility
  const [modalMessage, setModalMessage] = useState('');  // Modal message

  useEffect(() => {
    (async () => {
      const yearsAndTutes = await getAllNewYearsAndTutes();
      if (yearsAndTutes) {
        setAvailableYears(new Set(yearsAndTutes.map(yt => yt.year).sort((a, b) => a - b)));
        setAllYearsAndTutes(yearsAndTutes);
      }
    })();
  }, []);

  // A debounce function to prevent multiple requests
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  // Debounce the check to avoid rapid database queries
  const debounceCheckIndexNumber = debounce(async (fieldId, indexNumber) => {
    if (indexNumber) {
      const exists = await checkIndexNumberExists(indexNumber);
      setValidIndexes(prev => ({ ...prev, [fieldId]: exists }));
    }
    // Check for duplicates in the database (same Year + Tute + Index Number)
    if (indexNumber && year && tuteNumber) {
      const exists = await fetchSpecificResult(indexNumber, parseInt(year), tuteNumber);
      setIsDuplicate(prev => ({ ...prev, [fieldId]: exists !== null }));
    } /*else if (!year || !tuteNumber)
      setIsDuplicate(prev => ({ ...prev, [fieldId]: false }))*/
  }, 500);


  const checkLocalDuplicateIndexNumber = (id, indexNumber) => {
    return fields.some(field => field.id !== id && field.indexNumber === indexNumber);
  };


  const handleFieldChange = (fieldId, attribute, value) => {
    if (attribute === 'mark' && value) {
      // Clamp mark value between 0 and 100
      value = Math.max(0, Math.min(100, parseFloat(value)));
    }

    setFields(prevFields =>
      prevFields.map(field =>
        field.id === fieldId ? { ...field, [attribute]: value } : field
      )
    );

    if (attribute === 'indexNumber') {
      // Check indexNumber validity after the user stops typing
      debounceCheckIndexNumber(fieldId, value);
    }
  };

  const addField = () => {
    const lastField = fields[fields.length - 1];

    if (!lastField.indexNumber || !lastField.mark || !validIndexes[lastField.id] || isDuplicate[lastField.id]) {
      setModalMessage("Please fill in valid index numbers and marks before adding a new one.");
      setShowModal(true);
      return;
    }

    if (checkLocalDuplicateIndexNumber(lastField.id, lastField.indexNumber)) {
      setModalMessage("Duplicate index number found in the form.");
      setShowModal(true);
      return;
    }

    setFields([...fields, { id: Date.now(), indexNumber: '', mark: '' }]);
    setStdCount(stdCount + 1);
    setErrorMessage({ general: '', fields: [] });
  };


  const deleteField = (fieldId) => {
    setFields(prevFields => prevFields.filter(field => field.id !== fieldId));
    if (stdCount > 0)
      setStdCount(stdCount - 1);
  };

  const handleYearTuteChange = async (attribute, value) => {
    if (attribute === "year") {
      setYear(value);
      setTute('');
      const availableTutes = allYearsAndTutes?.filter(ayt => (ayt.year === parseInt(value, 10)));
      availableTutes && setRelevantTutes(new Set(availableTutes.map(at => at.tute)));
      //console.log(relevantTutes)
    } else if (attribute === "tuteNumber") {
      setTute(value);
    }

    const yearValue = attribute === "year" ? parseInt(value, 10) : parseInt(year, 10);
    const tuteValue = attribute === "tuteNumber" ? value : tuteNumber;

    if (yearValue && tuteValue) {
      const availableResults = await fetchResultsByYearTuteIndex("", yearValue, tuteValue);
      setStdCount((fields.length - 1) + availableResults?.length);

      // Revalidate all fields when year/tute changes
      await Promise.all(fields.map(async (field) => {
        const exists = await fetchSpecificResult(field.indexNumber, yearValue, tuteValue);
        setIsDuplicate(prev => ({ ...prev, [field.id]: exists !== null }));
      }));
    } else {
      // Reset validation state
      setIsDuplicate({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fields.length > 0 && checkLocalDuplicateIndexNumber(fields[fields.length - 1].id, fields[fields.length - 1].indexNumber)) {
      setModalMessage("Duplicate Index-Numbers found in the form.");
      setShowModal(true);
      return;
    }

    let errors = { general: '', fields: [] };

    // Check general required fields
    if (!year || !tuteNumber) {
      errors.general = "Paper No and Year are required.";
    }

    const processedResults = fields
      .filter(({ id, indexNumber, mark }) => indexNumber && mark && validIndexes[id] && !isDuplicate[id])
      .map(({ id, mark, ...rest }) => ({
        ...rest,
        year: parseInt(year, 10),
        tuteNumber: tuteNumber,
        mark: parseFloat(mark),
      }));

    // Check for individual field errors
    const fieldErrors = fields.map(({ id, indexNumber, mark }) => {
      if (!indexNumber || !mark) {
        return "Name, Index Number, and Marks are required.";
      }
      if (!validIndexes[id]) {
        return `Index Number "${indexNumber}" is invalid.`;
      }
      if (isDuplicate[id]) {
        return `Duplicate Index Number detected (${indexNumber}).`;
      }
      return "";
    });

    if (fieldErrors.some(error => error)) {
      errors.fields = fieldErrors;
    }

    // Update state with errors
    setErrorMessage(errors);

    // If there are errors, stop form submission
    if (errors.general || fieldErrors.some(error => error)) {
      return;
    }

    try {
      console.log("Submitted Results:", processedResults);
      if (!processedResults || processedResults.length <= 0) {
        setModalMessage("No valid data to process!");
        setShowModal(true);
        return;
      }
      else if (!processedResults[0].indexNumber || !processedResults[0].mark || !year || !tuteNumber) {
        setModalMessage("Index Number, and Mark are missing!");
        setShowModal(true);
        return;
      } else {
        navigate(`/AddMarksProcess`, {
          state: { results: processedResults }
        });
      }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      <header className="w-full bg-green-100 py-4 px-6 flex justify-between items-center custom-shadow">
        <NavBar />
      </header>

      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-20 lg:ml-20 md:ml-12 sm:ml-4 ml-0 font-segoe mt-4">
          Add Marks
        </h1>

        <div className="bg-[#FFFFFF] p-6 rounded-[10px] w-full md:w-[95%] lg:w-[90%] h-[70vh] border custom-borderMarks mt-4 flex flex-col relative">

          {/* Fixed Search and Tute Number Fields */}
          <div className="sticky top-0 z-10 bg-white w-full py-4">
            <div className='bg-[#DCFCE7] inline-flex p-2'>
              <label className="text-center">{stdCount} Students</label>
            </div>

            <div className="w-full flex flex-col md:flex-row md:justify-end space-y-4 md:space-y-0 md:space-x-4">
              <div className="w-full md:w-1/4">
                <select className="mt-1 block w-full md:w-[70%] p-2 border border-[#1BA147]" onChange={(e) => handleYearTuteChange("year", e.target.value)} required>
                  <option value="">Year</option>
                  {[...availableYears].map(eachYear => (
                    <option key={eachYear} value={eachYear}>{eachYear}</option>
                  ))}
                </select>
              </div>

              <div className="w-full md:w-1/4">
                <select className="mt-1 block w-full p-2 border border-[#1BA147] w-full md:w-[70%]"
                  onChange={(e) => handleYearTuteChange("tuteNumber", e.target.value)}
                  required>
                  <option value="">Paper No</option>
                  {[...relevantTutes].map(eachTute => (
                    <option key={eachTute} value={eachTute}>{eachTute}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Error Message */}
          <div className="min-h-[20px] mt-4 px-4 text-center md:text-right md:mr-40 font-weight-600">
            {errorMessage.general ? (
              <p className="text-red-500">{errorMessage.general}</p>
            ) : (
              <p>&nbsp;</p>
            )}
          </div>

          {/* Scrollable Form Fields */}
          <form onSubmit={handleSubmit} className="flex flex-col flex-grow overflow-auto space-y-4 pb-16 mt-4">
            {fields.map((field, index) => (
              <div key={field.id} className="flex flex-col md:flex-row md:space-x-4 justify-center items-center ">
                <div className="w-full md:w-1/3 mt-10">
                  <input
                    type="text"
                    className="mt-1 block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic"
                    placeholder="Index number"
                    value={field.indexNumber}
                    onChange={(e) => handleFieldChange(field.id, 'indexNumber', e.target.value)}
                    required
                  />
                  {validIndexes[field.id] === false ? (
                    <p className="text-red-500 text-sm mt-1">This index number is invalid.</p>
                  ) : isDuplicate[field.id] === true ? (
                    <p className="text-red-500 text-sm mt-1">Marks for this index number has been already added.</p>
                  ) : (<p>&nbsp;</p>)
                  }

                </div>
                <div className="w-full md:w-1/3 mt-3 flex items-center">
                  <input
                    type="number"
                    className="mt-1 block w-[60%] p-2 border border-[#1BA147] placeholder-italic no-spinner"
                    placeholder="Marks"
                    value={field.mark}
                    onChange={(e) => handleFieldChange(field.id, 'mark', e.target.value)}
                    required
                  />
                  {index > 0 && index === fields.length - 1 && (
                    <button
                      type="button"
                      onClick={() => deleteField(field.id)}
                      className="ml-8 p-2 text-red-600 border border-red-600 rounded"
                    >
                      Delete
                    </button>
                  )}

                </div>

                <div>
                  {/* {errorMessage.fields[index] && (
                    <p className="text-red-500 text-sm absolute bottom-0 left-0 mb-2 ml-2 font-weight-600">{errorMessage.fields[index] || "All fields are required!"}</p>
                  )} */}
                </div>
              </div>
            ))}

            <div className="absolute bottom-4 right-4 flex flex-row items-center space-x-4">
              <button
                type="button"
                onClick={addField}
                className="flex items-center bg-[#1BA147] text-white px-4 py-2 rounded-md hover:bg-green-600"
              >
                <span className="text-lg">+</span>
              </button>
              <button
                type="submit"
                className="bg-[#1BA147] text-white px-6 py-2 rounded-md hover:bg-green-600"
              >
                Process
              </button>
            </div>

          </form>

        </div>
      </main>
      {showModal && (
        <PopUpModal message={modalMessage} closeModal={() => setShowModal(false)} />
      )}
    </div>
  );
}
