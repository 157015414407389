import React, { useEffect, useState } from 'react';
import NavBar from '../navBar/navBar';
import { checkIndexNumberExists, fetchResultsByYearTuteIndex, updateResult } from '../../services/services';
import { useLocation, useNavigate } from 'react-router-dom';
import PopUpModal from '../addMarks/PopUpModal';

export default function UpdateMarks() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const year = queryParams?.get('year');
  const tuteNumber = queryParams?.get('tute');
  const indexNumber = queryParams?.get('index');

  const [fetchedResult, setFetchedResult] = useState(null);
  const [mark, setMark] = useState("");
  const [index, setIndex] = useState("");
  const [name, setName] = useState("");

  const [validIndex, setValidIndex] = useState(null); 
  const [isCheckingIndex, setIsCheckingIndex] = useState(false); 

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  const [modalMessage, setModalMessage] = useState('');  // Modal message state

  useEffect(() => {
    (async () => {
      if (indexNumber && year && tuteNumber) {
        const availableResult = await fetchResultsByYearTuteIndex(indexNumber, parseInt(year, 10), tuteNumber);
        if (availableResult) {
          setFetchedResult(availableResult[0]);
          setIndex(availableResult[0]?.indexNumber);
          setMark(availableResult[0]?.mark);
          setName(availableResult[0]?.name);
          debounceCheckIndexNumber(availableResult[0]?.indexNumber);
        }
      }
    })();
  }, [indexNumber, year, tuteNumber]);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const debounceCheckIndexNumber = debounce(async (indexNumber) => {
    if (indexNumber) {
      setIsCheckingIndex(true);  // Start checking
      const exists = await checkIndexNumberExists(indexNumber);
      setValidIndex(exists);
      setIsCheckingIndex(false);  // Done checking
    }
  }, 500);

  const updateCurrentResult = async (e) => {
    e.preventDefault();

    if (!mark || !index || !name) {
      setErrorMessage("All fields are required!");
      return;
    } else if (validIndex === false) {
      return;
    }

    const markValue = parseFloat(mark);
    if (markValue < 0 || markValue > 100) {
      setErrorMessage("Marks must be between 0 and 100!");
      return;
    }

    setErrorMessage('');

    const modifiedResult = {
      year: fetchedResult.year,
      tuteNumber: fetchedResult.tuteNumber,
      indexNumber: index,
      mark: markValue,
    };
    const response = await updateResult(fetchedResult.id, name, modifiedResult);
    if (response.isSuccess) {
      navigate(`/Dashboard`);
    } else {
      setModalMessage(response.message);  // Set failure message
      setShowModal(true);  // Show the modal
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      <header className="w-full bg-green-100 py-4 px-6 flex justify-between items-center custom-shadow">
        <NavBar />
      </header>

      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-4 font-segoe mt-4 md:ml-12">Update Marks</h1>
        <div className="bg-[#FFFFFF] p-4 md:p-6 rounded-[10px] w-full md:w-[95%]  md:h-[70vh] h-[76vh] md:h-[70vh] border custom-borderMarks mt-4 relative">
          <form onSubmit={updateCurrentResult} className="space-y-4">
            <div className="flex flex-col md:flex-row md:space-x-4 p-4 md:p-8">
              <div className="w-full md:w-1/3 mt-4 md:mt-10 md:ml-14">
                <input
                  type="text"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled
                />
              </div>
              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="text"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic"
                  placeholder="Index number"
                  value={index}
                  onChange={(e) => {
                    setIndex(e.target.value)
                    debounceCheckIndexNumber(e.target.value);
                  }}
                />
                {validIndex === false && !isCheckingIndex && (
                  <p className="text-red-500 text-sm mt-1">This index number is invalid.</p>
                )}
              </div>
              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="number"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic no-spinner"
                  placeholder="Marks"
                  value={mark}
                  onChange={(e) => setMark(e.target.value)}
                />
              </div>
            </div>

            {errorMessage ? (
              <div className="text-red-500 text-center mb-4">
                <p className='font-weight-600'>{errorMessage}</p>
              </div>
            ) : (
              <p className="mb-4">&nbsp;</p>
            )}

            <div className="absolute bottom-4 right-4">
              <button
                type="submit"
                className="bg-[#1BA147] text-white px-4 py-2 md:px-6 md:py-3 rounded-md hover:bg-green-600"
              >
                Process
              </button>
            </div>
          </form>
        </div>
      </main>
      {showModal && (
        <PopUpModal message={modalMessage} closeModal={() => setShowModal(false)} />
      )}
    </div>
  );
}
