import React, { useState } from 'react';
import NavBar from '../navBar/navBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { addNewResults } from '../../services/services';
import PopUpModal from './PopUpModal';

export default function AddMarksProcess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { results } = location.state || {}; // Access the passed data
  const [showModal, setShowModal] = useState(false);  // State to control modal visibility
  const [modalMessage, setModalMessage] = useState('');  // Modal message

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  
  // Calculate total pages
  const totalPages = results ? Math.ceil(results.length / rowsPerPage) : 0;

  // Paginated results
  const paginatedResults = results ? results.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  ) : [];

  const getPaginationButtons = () => {
    const maxButtons = 5;
    const firstPage = 1;
    const lastPage = totalPages;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 4) {
        startPage = 1;
        endPage = Math.min(maxButtons, totalPages);
      } else if (currentPage >= totalPages - 4) {
        startPage = Math.max(totalPages - maxButtons + 1, 1);
        endPage = totalPages;
      } else {
        const gap = Math.floor(maxButtons / 2);
        startPage = currentPage - gap;
        endPage = currentPage + gap;
        
        if (startPage < 1) {
          startPage = 1;
          endPage = Math.min(maxButtons, totalPages);
        }
        
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(totalPages - maxButtons + 1, 1);
        }
      }
    }

    const buttons = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    
    if (startPage > 2) {
      buttons.unshift(firstPage);
    }
    
    if (endPage < totalPages - 1) {
      buttons.push(lastPage);
    }

    return buttons;
  };

  // Ensure the table always shows 5 rows
  const filledResults = paginatedResults.slice(); 
  while (filledResults.length < rowsPerPage) {
    filledResults.push({ indexNumber: '', name: '', year: '', tuteNumber: '', mark: '' }); // Empty rows
  }

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleAddMarks = async () => {
    if (results && results.length > 0) {
      try {
        await addNewResults(results);
        navigate(`/Dashboard`);
      } catch (error) {
        console.log(error);
      }
    } else {
      setModalMessage("No results to be added!");
      setShowModal(true);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      <header className="w-full bg-green-100 py-4 px-6 flex justify-between items-center custom-shadow">
        <NavBar />
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-14 font-segoe mt-4">Add Marks</h1>

        {/* Table Container */}
        <div className="bg-[#FFFFFF] p-6 rounded-[10px] w-[95%] h-[70vh] border custom-borderMarks mt-4 relative">
        <div className="overflow-x-auto">
            <table className="bg-white border-collapse border border-gray-300 mt-4 mx-auto w-full">
              <thead>
                <tr className="bg-white">
                  <th
                    className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10"
                    style={{ width: '20%' }}
                  >
                    Index number
                  </th>
                  <th
                    className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10"
                    style={{ width: '20%' }}
                  >
                    Year
                  </th>
                  <th
                    className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10"
                    style={{ width: '20%' }}
                  >
                    Paper No
                  </th>
                  <th
                    className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10"
                    style={{ width: '20%' }}
                  >
                    Marks
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedResults?.map((result, idx) => (
                  <tr key={idx} className="bg-white">
                    <td className="table-cell" style={{ width: '20%' }}>
                      {result.indexNumber}
                    </td>
                    <td className="table-cell" style={{ width: '20%' }}>
                      {result.year}
                    </td>
                    <td className="table-cell" style={{ width: '20%' }}>
                      {result.tuteNumber}
                    </td>
                    <td className="table-cell" style={{ width: '20%' }}>
                      {result.mark}
                    </td>
                  </tr>
                ))}

                {/* Render empty rows if the data is less than 5 */}
                {paginatedResults.length < rowsPerPage &&
                  [...Array(rowsPerPage - paginatedResults.length)].map((_, emptyRowIdx) => (
                    <tr key={`empty-${emptyRowIdx}`} className="bg-white">
                      <td className="table-cell" style={{ width: '20%' }}>&nbsp;</td>
                      <td className="table-cell" style={{ width: '20%' }}>&nbsp;</td>
                      <td className="table-cell" style={{ width: '20%' }}>&nbsp;</td>
                      <td className="table-cell" style={{ width: '20%' }}>&nbsp;</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>


          {/* Pagination */}
          <div className="flex justify-center mt-4 absolute bottom-16 left-1/2 transform -translate-x-1/2">
            <button
              className={`px-2 py-1 border rounded-l border-gray-300 bg-white hover:bg-gray-100 text-sm ${currentPage === 1 ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              &lt;
            </button>

            {getPaginationButtons().map((pageNumber, idx) => (
              <button
                key={idx}
                className={`px-2 py-1 border border-gray-300 text-sm ${currentPage === pageNumber ? 'bg-green-600 text-white' : 'bg-white hover:bg-gray-100 text-gray-700'}`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}

            <button
              className={`px-2 py-1 border rounded-r border-gray-300 bg-white hover:bg-gray-100 text-sm ${currentPage === totalPages ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              &gt;
            </button>
          </div>


          {/* Add Marks Button */}
          <div className="absolute bottom-4 right-4">
            <button
              className="bg-green-600 text-white font-bold py-2 px-6 rounded hover:bg-green-700"
              onClick={handleAddMarks}
            >
              Add Marks
            </button>
          </div>
        </div>
      </main>

      {showModal && (
        <PopUpModal message={modalMessage} closeModal={() => setShowModal(false)} />
      )}
    </div>
  );
}
