import React from 'react';
import { useLocation } from 'react-router-dom';

const NavBar = () => {
  const location = useLocation();
  
  // Check if the current path matches the href of each link
  const isActive = (path) => location.pathname === path;

  return (
     <header className="fixed top-0 left-0 w-full bg-[#DCFCE76B] py-4 custom-shadow z-50">
        <nav className="container mx-auto flex flex-wrap justify-between items-center px-4">
          <div className="text-lg font-semibold"></div>
          <div className="space-x-4 sm:space-x-6 lg:space-x-8">
            <a
              href="/"
              className={`${
                isActive('/') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              Home
            </a>
            <a
              href="AboutUs"
              className={`${
                isActive('/AboutUs') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              About Us
            </a>
            <a
              href="ContactUs"
              className={`${
                isActive('/ContactUs') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              Contact Us
            </a>

            <a
              href="TimeTable"
              className={`${
                isActive('/TimeTable') ? 'text-green-600' : 'text-black'
              } hover:text-green-600 font-weight-600 font-segoe`}
            >
              Time Table
            </a>
            
          </div>
        </nav>
      </header> 
  );
};

export default NavBar;
