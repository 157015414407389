import React from "react";
import NavBar from "../home/NavBar";
import "../../App.css"; 

const ContactUs = () => {
  const phoneNumber = "0776924610"; 

  return (
    <div className="flex flex-col min-h-screen bg-custom-gradient">
      {/* Fixed Navigation Bar */}
      <NavBar />

      {/* Contact Section */}
      <div className="flex-grow flex items-center justify-center py-20 px-4">
        <div className="text-center max-w-lg mx-auto bg-opacity-60 backdrop-blur-lg rounded-lg shadow-lg p-10 bg-[#DCFCE76B]">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Contact Us</h1>
          <p className="text-lg text-gray-700 mb-6">
            For further details, feel free to reach us at:
          </p>
          
          {/* Center aligned block */}
          <div className="flex flex-col items-center space-y-4">
            <p 
              className="text-2xl font-semibold text-blue-700 hover:text-blue-800 transition duration-300 cursor-pointer"
            >
              Voice - {phoneNumber}
            </p>
            <p 
              className="text-2xl font-semibold text-blue-700 hover:text-blue-800 transition duration-300 cursor-pointer"
            >
              WhatsApp - 0778138634
            </p>
          </div>
          
          {/* <p className="text-sm text-gray-500 mt-4">
            We're available 9 AM to 6 PM, Monday to Friday.
          </p> */}
        </div>
      </div>


      
    </div>
  );
};

export default ContactUs;
