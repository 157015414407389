import React, { useEffect, useState } from 'react';
import NavBar from '../navBar/navBar';
import { updateYearAndTute } from '../../services/services';
import { useLocation, useNavigate } from 'react-router-dom';
import PopUpModal from '../addMarks/PopUpModal';

export default function EditTute() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const year = queryParams?.get('year');
  const tuteNumber = queryParams?.get('tute');

  const [updateYear, setUpdateYear] = useState("");
  const [updateTute, setUpdateTute] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  const [modalMessage, setModalMessage] = useState('');  // Modal message state

  useEffect(() => {
    (async () => {
      if (year && tuteNumber) {
        setUpdateYear(year);
        setUpdateTute(tuteNumber);
      }
    })();
  }, [year, tuteNumber]);

  const updateCurrentYearTute = async (e) => {
    e.preventDefault();

    if (!updateTute || !updateYear) {
      setErrorMessage("All fields are required!");
      return;
    }

    setErrorMessage('');

    const modifiedYearTute = {
      year: parseInt(updateYear, 10),
      tute: updateTute,
    };
    const response = await updateYearAndTute(parseInt(year, 10), tuteNumber, modifiedYearTute);
    if (response.isSuccess) {
      setModalMessage(response.message);
      setShowModal(true);
      navigate(`/AddTute`);
    } else {
      setModalMessage(response.message);  // Set failure message
      setShowModal(true);  // Show the modal
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center">
      <header className="w-full bg-green-100 py-4 px-6 flex justify-between items-center custom-shadow">
        <NavBar />
      </header>

      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-4 font-segoe mt-4 md:ml-12">Edit Paper</h1>
        <div className="bg-[#FFFFFF] p-4 md:p-6 rounded-[10px] w-full md:w-[95%]  md:h-[70vh] h-[76vh] md:h-[70vh] border custom-borderMarks mt-4 relative">
          <form onSubmit={updateCurrentYearTute} className="space-y-4">
            <div className="flex flex-col md:flex-row md:space-x-4 p-4 md:p-8">

              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="number"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic no-spinner "
                  placeholder="Year"
                  value={updateYear}
                  onChange={(e) => setUpdateYear(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/3 mt-4 md:mt-10">
                <input
                  type="text"
                  className="block w-full md:w-[60%] p-2 border border-[#1BA147] placeholder-italic no-spinner"
                  placeholder="Paper No"
                  value={updateTute}
                  onChange={(e) => setUpdateTute(e.target.value)}
                />
              </div>
            </div>

            {errorMessage ? (
              <div className="text-red-500 text-center mb-4">
                <p className='font-weight-600'>{errorMessage}</p>
              </div>
            ) : (
              <p className="mb-4">&nbsp;</p>
            )}

            <div className="absolute bottom-4 right-4">
              <button
                type="submit"
                className="bg-[#1BA147] text-white px-4 py-2 md:px-6 md:py-3 rounded-md hover:bg-green-600"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </main>
      {showModal && (
        <PopUpModal message={modalMessage} closeModal={() => setShowModal(false)} />
      )}
    </div>
  );
}
