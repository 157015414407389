import React from 'react';

const DeleteConfirmationStudentModal = ({ indexNumber, onConfirm, onCancel }) => {
  const handleConfirm = async () => {
    // Call the onConfirm function and wait for it to complete
    await onConfirm(indexNumber);
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
        <p className="mb-4">
          Are you sure you want to delete this student?
        </p>
        <div className="flex justify-end">
          <button
            className="bg-red-600 text-white px-4 py-2 rounded mr-2 hover:bg-red-700"
            onClick={handleConfirm}
          >
            Confirm
          </button>
          <button
            className="bg-gray-300 px-4 py-2 rounded hover:bg-gray-400"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationStudentModal;
