import React from 'react';
import NavBar from '../navBar/navBar';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#FFFFFF] flex flex-col">
      {/* Header */}
      <header className="w-full bg-green-100 py-4 custom-shadow">
        <NavBar />
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-weight-600 text-black mb-4 self-start ml-8 font-segoe mt-6">Settings</h1>
        <div className="bg-[#FFFFFF] p-6 rounded-[10px] w-[95%] h-[70vh] border custom-borderMarks mt-4 flex flex-col relative">
          <button className="bg-[#DCFCE7C9] border border-[#00AB38] text-black font-segoe font-weight-600 button-class relative flex items-center justify-center"
            onClick={() => navigate('/AddTute')}
          >
            <span className="absolute top-2 left-2 text-black font-segoe font-weight-600">Add Paper</span>
            <img src="arrowicon.png" alt="arrow icon" className="absolute bottom-2 right-2 w-[18px] h-[18px]" />
          </button>
          <br />
          <button className="bg-[#DCFCE7C9] border border-[#00AB38] text-black font-segoe font-weight-600 button-class relative flex items-center justify-center"
            onClick={() => navigate('/AddStudent')}
          >
            <span className="absolute top-2 left-2 text-black font-segoe font-weight-600">Add Student</span>
            <img src="arrowicon.png" alt="arrow icon" className="absolute bottom-2 right-2 w-[18px] h-[18px]" />
          </button>
        </div>
      </main>

      {/* Responsive Styles */}
      <style jsx>{`
        /* Mobile (smaller than 640px) */
        @media (max-width: 640px) {
          h1 {
            font-size: 1.5rem;
            margin-left: 1rem;
            margin-top: 1rem;
          }
          .w-[95%] {
            width: 100%;
            height: auto;
          }
          .button-class {
            width: 50%;
            height: 25%;
            font-size: 1.25rem; /* Increase font size for better visibility */
          }
        }

        /* Tablet (641px - 1024px) */
        @media (min-width: 641px) and (max-width: 1024px) {
          h1 {
            font-size: 1.75rem;
            margin-left: 2rem;
            margin-top: 1.5rem;
          }
          .w-[95%] {
            width: 90%;
            height: 60vh;
          }
          .button-class {
            width: 25%;
            height: 15%;
          }
        }

        /* Laptop (1025px and up) */
        @media (min-width: 1025px) {
          h1 {
            font-size: 2rem;
          }
          .w-[95%] {
            width: 95%;
            height: 70vh;
          }
          .button-class {
            width: 15%;
            height: 20%;
          }
        }
      `}</style>
    </div>
  );
};

export default Settings;
