import React, { useEffect, useState } from 'react';
import "../../App.css";
import { useNavigate } from 'react-router-dom';
import { getAllNewYearsAndTutes, fetchResultsForStudent } from '../../services/services';

export default function Search() {
  const [availableYears, setAvailableYears] = useState(new Set());
  const [indexNo, setIndexNo] = useState("");
  const [year, setYear] = useState("");
  const [tute, setTute] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const yearsAndTutes = await getAllNewYearsAndTutes();
      if (yearsAndTutes) {
        setAvailableYears(new Set(yearsAndTutes.map(yt => yt.year).sort()));
      }
    })();
  }, []);

  const handleSearch = async () => {
    // Check if any fields are empty
    if (!indexNo || !year || !tute) {
      setErrorMessage("All fields are required!");
      return;
    }

    // Clear the error message if all fields are filled
    setErrorMessage("");

    const results = await fetchResultsForStudent(indexNo, parseInt(year, 10), tute);

    // Navigate to the StudentResult page with the results
    navigate('/StudentResult', { state: { results } });
  };

  const handleReset = () => {
    setYear("");
    setTute("");
    setIndexNo("");
    setErrorMessage("");  // Clear error message on reset
  };

  return (
    <div className="flex flex-col items-center p-4">
      <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 w-full max-w-screen-md">
        <input
          type="text"
          value={indexNo}
          placeholder="Index Number"
          className="border border-green-400 py-2 px-4 rounded text-sm focus:outline-none focus:ring focus:border-green-500 font-segoe placeholder-italic w-full sm:w-auto flex-grow"
          onChange={(e) => setIndexNo(e.target.value)}
        />

        <input
          type="text"
          value={tute}
          placeholder="Paper No"
          className="border border-green-400 py-2 px-4 rounded text-sm focus:outline-none focus:ring focus:border-green-500 font-segoe placeholder-italic w-full sm:w-auto flex-grow no-spinner"
          onChange={(e) => setTute(e.target.value)}
        />

        <select className="border border-green-400 py-2 px-4 rounded text-sm focus:outline-none focus:ring focus:border-green-500 font-segoe w-full sm:w-auto flex-grow"
          value={year}
          onChange={(e) => setYear(e.target.value)}>
          <option value="">Year</option>
          {[...availableYears].map(eachYear => (
            <option key={eachYear} value={eachYear}>{eachYear}</option>
          ))}
        </select>

        <button className="bg-green-600 text-white py-2 px-6 rounded hover:bg-green-700 border-green-400 font-segoe w-full sm:w-auto" onClick={handleSearch}>
          Search
        </button>

        <button className="bg-[#FFFFFF] border border-green-400 text-[#1BA147] py-2 px-6 rounded hover:bg-green-700 hover:text-white font-segoe w-full sm:w-auto" onClick={handleReset}>
          Reset
        </button>
      </div>

      {/* Display the error message */}
      {errorMessage && (
        <div className="text-red-500 mt-4 text-center font-weight-600">
          {errorMessage}
        </div>
      )}
    </div>
  );
}
