import React from 'react'
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';

function RouteProtection({ children }) {
    const { currentUser } = useAuth();

    if (!currentUser) {
        return <Navigate to={"/65fg4654bfdf48fdsjxkmm-Login"} replace />
    } else {
        return children;
    }
}

export default RouteProtection;