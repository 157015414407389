import React from "react";
import NavBar from "../home/NavBar";
import "../../App.css";

const AboutUs = () => {
  return (
    <div className="flex flex-col min-h-screen bg-custom-gradient">
      {/* Fixed Navigation Bar */}
      <NavBar />

      {/* Hero Section */}
      <section className="flex flex-col items-center justify-center text-center py-20 bg-[#7DE96D] text-black">
        <h1 className="text-4xl font-bold mb-4">Best Chemistry Class!</h1>
        {/* <p className="text-lg max-w-xl mb-6">
          We are passionate about creating solutions that bring value to our community.
          Discover who we are and what drives us to deliver excellence.
        </p> */}
        
        {/* Teacher Specialties under Best Chemistry Class */}
        <div className="bg-[#DCFCE76B] mt-6 text-center w-[70%] p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-[#333333] mb-4">අපගේ සුවිශේෂිත්වය!</h2>
          <ul className="list-disc list-inside text-gray-700 custom-list">
            <li className="mb-2 text-left">
              <span className="font-bold"> නියමිත කාලයේදී විෂය නිර්දේශය ආවරණය කිරිම.</span>
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold">නිවැරදි විෂය කරුනු ඉගැන්වීම.</span> 
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold">සෑම සතියකම පෙර පන්තියේ පාඩමට අදාළ ප්‍රශ්න ලියවීම.</span> 
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold">විභාගය වෙනුවෙන් සැකසූ විශේෂ ප්‍රශ්න පත්‍ර මගින් සිසුන් විභාගය සඳහා සූදානම් කරවීම.</span> 
            </li>
          </ul>
        </div>

        <div className="bg-[#DCFCE76B] mt-6  w-[70%] p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-[#333333] mb-4 text-center">Paper Class</h2>
          <ul className="list-disc list-inside text-gray-700 custom-list">
            <li className="mb-2 text-left">
              <span className="font-bold "> සෑම සතියකම විභාග මට්ටමේ ප්‍රශ්න පත්‍රයක් ලියවීම.</span>
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold ">සෑම සතියකම කලින් සතියේ ලියූ ප්‍රශ්න පත්‍රය සඳහා ලකුණු ලබාදීම සහ සාකච්ඡා කිරීම.</span> 
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold">ලකුණු අනුව සිසුන්ට ස්ථාන ලබා දීම.</span> 
            </li>
          </ul>
        </div>

        <div className="bg-[#DCFCE76B] mt-6 w-[70%] p-6 rounded-lg shadow-lg flex justify-center">
          <h2 className="text-[21px] font-bold text-[#333333] mb-4 text-left">
            දැනට ප්‍රදේශයේ ජනප්‍රිය පාසල් වල වැඩිම සිසුන් ගණනක් සහභාගී වේ.
          </h2>
        </div>


        <div className="bg-[#DCFCE76B] mt-6  w-[70%] p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-[#333333] mb-4 text-center">About the Teacher</h2>
          <ul className="list-disc list-inside text-gray-700 custom-list">
            <li className="mb-2 text-left">
              <span className="font-bold "> රුහුණ විශ්ව විද්‍යාලයේ රසායන විද්‍යාව පිළිබදව පළමු පන්තියේ සාමාර්ථයක් හිමිය.<br/>(Bsc. Hons Special in Chemistry).</span>
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold ">2009 උපාධි ප්‍රධානෝත්සවයේදී රසායන විද්‍යාව සදහා පිරිනැමෙන රන් පදක්කම ලබාගෙන ඇත.</span> 
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold">රසායන විද්‍යා සහකාර කථිකාචාර්ය ලෙස වසර තුනක අත්දැකීම් ඇත.</span> 
            </li>
            <li className="mb-2 text-left">
              <span className="font-bold">රසායන විද්‍යා ගුරුවරියක ලෙස වසර 12 ක් ඉගැන්වීම් සිදු කරයි.</span> 
            </li>
          </ul>
        </div>
      </section>

      {/* Mission Statement
      <section className="flex flex-col items-center text-center py-16 px-8 bg-white">
        <h2 className="text-3xl font-bold mb-6 text-blue-700">Our Mission</h2>
        <p className="text-gray-600 max-w-3xl mb-8">
          At [Your Company], our mission is to innovate and create solutions that make life easier.
          We believe in the power of technology to bring about positive change, and we are committed
          to making that happen through our projects.
        </p>
      </section> */}

      {/* Team Section */}
      {/* <section className="flex flex-col items-center text-center py-16 px-8 bg-gray-100">
        <h2 className="text-3xl font-bold mb-6 text-blue-700">Meet the Team</h2>
        <div className="flex flex-wrap justify-center gap-8 max-w-5xl"> */}
          {/* Team Member 1 */}
          {/* <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              src="https://via.placeholder.com/150"
              alt="Team member 1"
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <h3 className="text-xl font-bold">Jane Doe</h3>
            <p className="text-gray-600">CEO & Founder</p>
          </div> */}

          {/* Team Member 2 */}
          {/* <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              src="https://via.placeholder.com/150"
              alt="Team member 2"
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <h3 className="text-xl font-bold">John Smith</h3>
            <p className="text-gray-600">CTO & Co-Founder</p>
          </div> */}

          {/* Team Member 3 */}
          {/* <div className="bg-white p-6 rounded-lg shadow-lg">
            <img
              src="https://via.placeholder.com/150"
              alt="Team member 3"
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
            <h3 className="text-xl font-bold">Alex Johnson</h3>
            <p className="text-gray-600">Lead Developer</p>
          </div>
        </div>
      </section> */}

      {/* Call to Action */}
      {/* <section className="flex flex-col items-center text-center py-16 bg-blue-600 text-white">
        <h2 className="text-3xl font-bold mb-6">Join Us on Our Journey</h2>
        <p className="text-lg max-w-xl mb-8">
          We're always on the lookout for talented and passionate individuals to join our team.
          Want to be part of something amazing? Reach out and let's make it happen!
        </p>
        <button className="px-6 py-3 bg-white text-blue-600 font-bold rounded-full shadow-lg hover:bg-gray-200 transition duration-300">
          Contact Us
        </button>
      </section> */}
    </div>
  );
};

export default AboutUs;
