import React from 'react';

export default function PopUpModal({ message, closeModal }) {
  return (
    <div
      className="fixed inset-0 flex items-center justify-center z-50"
      style={{ backgroundColor: '#C4F1CDCF' }} // Light green overlay
    >
      <div className="bg-white p-4 md:p-6 rounded-md shadow-lg max-w-md md:max-w-xl w-full">
        <p className="text-base md:text-lg font-bold mb-4 text-center">{message}</p>
        <div className="flex justify-center">
          <button
            onClick={closeModal}
            className={`mt-4 px-4 py-2 rounded-md text-white ${
              message === "Paper Added Successfully!" 
                ? "bg-green-600 hover:bg-green-700" 
                : message === "Student Added Successfully!"
                ? "bg-green-600 hover:bg-green-700"
                : message === "Year & Paper Deleted Successfully!"
                ? "bg-green-600 hover:bg-green-700"  // Apply different styles for student success
                : message === "Student Deleted Successfully!"
                ? "bg-green-600 hover:bg-green-700"
                : "bg-[#edb95e] hover:bg-[#d99a52]"
            }`}
            
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
