import React from 'react'

export default function ResultRow({ result }) {
    return (
        <tr>
            <td className="table-cell">{result?.rank}</td>
            <td className="table-cell">{result?.indexNumber}</td>
            <td className="table-cell">{result?.name?.length > 30 ? result.name.slice(0, 30) + '...' : result.name}</td>
            <td className="table-cell">{result?.mark}</td>
        </tr>
    )
}
