import React, { useEffect, useState } from 'react';
import { addNewYearAndTute, deleteYearAndTute, getAllNewYearsAndTutes } from '../../services/services';
import NavBar from '../navBar/navBar';
import PopUpModal from '../addMarks/PopUpModal';
import DeleteConfirmationModal from '../editStudent/DeleteConfirmationModal.jsx';

const AddTute = () => {
  const [year, setYear] = useState('');
  const [tute, setTute] = useState('');
  const [existingYearsTutes, setExistingYearsTutes] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [yearToDelete, setYearToDelete] = useState('');
  const [tuteToDelete, setTuteToDelete] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const totalPages = Math.ceil(existingYearsTutes.length / rowsPerPage);

  const filteredTutes = existingYearsTutes.filter(yt => 
    yt.tute.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedTutes = filteredTutes.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPaginationButtons = () => {
    const maxButtons = 5;
    const firstPage = 1;
    const lastPage = totalPages;
    let startPage, endPage;
  
    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 4) {
        startPage = 1;
        endPage = Math.min(maxButtons, totalPages);
      } else if (currentPage >= totalPages - 4) {
        startPage = Math.max(totalPages - maxButtons + 1, 1);
        endPage = totalPages;
      } else {
        const gap = Math.floor(maxButtons / 2);
        startPage = currentPage - gap;
        endPage = currentPage + gap;
        
        if (startPage < 1) {
          startPage = 1;
          endPage = Math.min(maxButtons, totalPages);
        }
        
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(totalPages - maxButtons + 1, 1);
        }
      }
    }
  
    const buttons = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    
    if (startPage > 2) {
      buttons.unshift(firstPage); 
    }
    
    if (endPage < totalPages - 1) {
      buttons.push(lastPage);
    }
  
    return buttons;
  };

  const paginationButtons = getPaginationButtons();
  
  useEffect(() => {
    getExistingYearsTutes();
  }, []);

  async function getExistingYearsTutes() {
    const ytData = await getAllNewYearsAndTutes();
    if (ytData) {
      setExistingYearsTutes(ytData.sort((a, b) => b.year - a.year));
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (!year || !tute) {
      setErrorMessage("All fields are required");
      return;
    }

    setYear('');
    setTute('');
    setErrorMessage('');

    const response = await addNewYearAndTute({ year: parseInt(year), tute: tute });
    if (response.isSuccess) {
      getExistingYearsTutes();
      setModalMessage("Paper Added Successfully!");
      setShowModal(true);
    } else {
      setModalMessage(response.message);
      setErrorMessage(response.message);
    }
  }

  function handleConfirmDelete() {
    deleteYearAndTute(yearToDelete, tuteToDelete).then(response => {
      if (response?.isSuccess) {
        getExistingYearsTutes();
        setModalMessage('Year & Paper Deleted Successfully!');
        setShowModal(true);
      } else {
        setModalMessage('Failed to delete Year & Paper.');
        setShowModal(true);
      }
      setShowDeleteModal(false);
    });
  }

  function handleDelete(year, tute) {
    setYearToDelete(year);
    setTuteToDelete(tute);
    setShowDeleteModal(true);
  }

  return (
    <div className="min-h-screen bg-[#FFFFFF] flex flex-col">
      <header className="w-full bg-green-100 py-4 custom-shadow">
        <NavBar />
      </header>

      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-semibold text-black mb-4 self-start ml-36 lg:ml-36 md:ml-24 sm:ml-8 ml-0 font-segoe mt-6">
          Add Paper
        </h1>
        <div className="bg-[#FFFFFF] p-6 rounded-lg w-full sm:w-[95%] md:w-[90%] lg:w-[80%] border custom-borderMarks mt-4 flex flex-col relative mb-8">
          <form onSubmit={handleSubmit} className="flex flex-col justify-between">
            <div className="flex flex-col gap-4 p-4 bg-[#EEEEEE80] rounded sm:flex-row sm:gap-6">
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                <input
                  type="number"
                  placeholder="Year"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] no-spinner placeholder-italic"
                  onChange={(e) => setYear(e.target.value)}
                  value={year}
                />
              </div>
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                <input
                  type="text"
                  placeholder="Paper No"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] placeholder-italic no-spinner"
                  onChange={(e) => setTute(e.target.value)}
                  value={tute}
                />
              </div>
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                <input
                  type="text"
                  placeholder="Search by Paper No"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] placeholder-italic no-spinner lg:ml-[180%]"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>

            <br />
            <div className="flex mb-6 h-6">
              <p className={`h6 text-red-500 text-sm font-semibold text-center ${errorMessage ? '' : 'invisible'}`}>
                {errorMessage}
              </p>
            </div>

            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="bg-green-600 text-white font-semibold py-2 px-4 rounded hover:bg-green-700 transition duration-300 border border-[#1BA147]"
              >
                Add Tute
              </button>
            </div>
          </form>

          <div className="overflow-x-auto mt-6">
            {existingYearsTutes.length > 0 && (
              <table className="bg-white border-collapse border border-gray-300 mt-4 mx-auto w-full sm:w-3/4 md:w-1/2 lg:w-1/2"
              style={{ width: '100%' }}>
                <thead>
                  <tr className="bg-white">
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '30%' }}>Year</th>
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '40%' }}>Paper No</th>
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '30%' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedTutes.map((yt, index) => (
                    <tr key={index} className="bg-white">
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>{yt?.year}</td>
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '40%' }}>{yt?.tute}</td>
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>
                        <a href={`/EditTute?year=${yt?.year}&tute=${yt?.tute}`} className="text-blue-500 hover:underline">
                          Edit &nbsp;
                        </a>
                        <a href="#" onClick={() => handleDelete(yt.year, yt.tute)} className="text-red-500 hover:underline">
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}

                  {paginatedTutes.length < rowsPerPage &&
                    [...Array(rowsPerPage - paginatedTutes.length)].map((_, emptyRowIdx) => (
                      <tr key={`empty-${emptyRowIdx}`} className="bg-white">
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>&nbsp;</td>
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '40%' }}>&nbsp;</td>
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>&nbsp;</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>

          {existingYearsTutes.length > 0 && (
            <div className="flex justify-center mt-4 w-full">
              <div className="inline-flex overflow-hidden rounded-lg border border-gray-300">
                <button
                  className={`px-3 py-1 ${currentPage === 1 ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  &lt;
                </button>
                {paginationButtons.map((page) => (
                  <button
                    key={page}
                    className={`px-3 py-1 ${currentPage === page ? 'bg-green-600 text-white' : 'bg-white hover:bg-gray-100'}`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                ))}
                <button
                  className={`px-3 py-1 ${currentPage === totalPages ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  &gt;
                </button>
              </div>
            </div>
          )}
        </div>
      </main>

      {showModal && (
        <PopUpModal message={modalMessage} closeModal={() => setShowModal(false)} />
      )}
      {showDeleteModal && (
        <DeleteConfirmationModal
          year={yearToDelete}
          tute={tuteToDelete}
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default AddTute;
