import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#DCFCE76B] text-black py-2  bottom-0 w-full">
      <div className="container mx-auto px-4">
        

        
        <h2 className="text-xs  text-center">Syncrones</h2>
          
        </div>
      
    </footer>
  );
};

export default Footer;
