import React, { useEffect, useState } from 'react';
import { addNewStudent, deleteStudent, getAllStudents } from '../../services/services';
import NavBar from '../navBar/navBar';
import PopUpModal from '../addMarks/PopUpModal';
import DeleteConfirmationStudentModal from '../editStudent/DeleteConfirmStudent'; // Update the path accordingly

const AddStudent = () => {
  const [name, setName] = useState('');
  const [indexNumber, setIndexNumber] = useState('');
  const [school, setSchool] = useState('');
  const [existingStudents, setExistingStudents] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false); // New state for delete confirmation modal
  const [studentToDelete, setStudentToDelete] = useState(null); // New state to track the student to delete

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;
  const totalPages = Math.ceil(existingStudents.length / rowsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPaginationButtons = () => {
    const maxButtons = 5;
    const firstPage = 1;
    const lastPage = totalPages;
    let startPage, endPage;

    if (totalPages <= maxButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 4) {
        startPage = 1;
        endPage = Math.min(maxButtons, totalPages);
      } else if (currentPage >= totalPages - 4) {
        startPage = Math.max(totalPages - maxButtons + 1, 1);
        endPage = totalPages;
      } else {
        const gap = Math.floor(maxButtons / 2);
        startPage = currentPage - gap;
        endPage = currentPage + gap;
        
        if (startPage < 1) {
          startPage = 1;
          endPage = Math.min(maxButtons, totalPages);
        }
        
        if (endPage > totalPages) {
          endPage = totalPages;
          startPage = Math.max(totalPages - maxButtons + 1, 1);
        }
      }
    }

    const buttons = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    
    if (startPage > 2) {
      buttons.unshift(firstPage);
    }
    
    if (endPage < totalPages - 1) {
      buttons.push(lastPage);
    }

    return buttons;
  };

  useEffect(() => {
    if (existingStudents.length <= 0) getExistingStudents();
  }, []);

  async function getExistingStudents() {
    const stdData = await getAllStudents();
    if (stdData) {
      setExistingStudents(stdData.sort());
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!indexNumber || !name || !school) {
      setErrorMessage('All fields are required');
      return;
    }

    setErrorMessage('');

    const response = await addNewStudent({ indexNumber, name, school });
    setName('');
    setSchool('');
    setIndexNumber('');
    if (response?.isSuccess) {
      getExistingStudents();
      setModalMessage('Student Added Successfully!');
      setShowModal(true);
    } else {
      setModalMessage(response.message);
      setErrorMessage(response.message);
    }
  }

  async function handleDelete(indexNumber) {
    setStudentToDelete(indexNumber);
    setShowDeleteModal(true);
  }

  async function confirmDelete(indexNumber) {
    try {
      const response = await deleteStudent(indexNumber);
      if (response?.isSuccess) {
        await getExistingStudents();
        setModalMessage('Student Deleted Successfully!');
      } else {
        setModalMessage('Failed to delete student.');
      }
    } catch (error) {
      setModalMessage('An error occurred while deleting the student.');
    }
    setShowDeleteModal(false);
    setShowModal(true);
  }

  const paginationButtons = getPaginationButtons();
  const filteredStudents = existingStudents.filter((student) =>
    student.indexNumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div className="min-h-screen bg-[#FFFFFF] flex flex-col">
      <header className="w-full bg-green-100 py-4 custom-shadow">
        <NavBar />
      </header>

      <main className="flex flex-col items-center w-full mt-8 px-4">
        <h1 className="text-2xl font-semibold text-black mb-4 self-start ml-36 lg:ml-36 md:ml-24 sm:ml-8 ml-0 font-segoe mt-6">
          Add Student
        </h1>
        <div className="bg-[#FFFFFF] p-6 rounded-lg w-full sm:w-[95%] md:w-[90%] lg:w-[80%] border custom-borderMarks mt-4 flex flex-col relative mb-8">
          <form onSubmit={handleSubmit} className="flex flex-col justify-between">
            <div className="flex flex-col gap-4 p-4 bg-[#EEEEEE80] rounded sm:flex-row sm:gap-6">
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                <input
                  type="text"
                  placeholder="Student Name"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] placeholder-italic no-spinner"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                <input
                  type="text"
                  placeholder="School Name"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] no-spinner placeholder-italic"
                  onChange={(e) => setSchool(e.target.value)}
                  value={school}
                />
              </div>
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%]">
                <input
                  type="text"
                  placeholder="Index Number"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] placeholder-italic no-spinner"
                  onChange={(e) => setIndexNumber(e.target.value)}
                  value={indexNumber}
                />
              </div>
              <div className="relative w-full sm:w-[40%] md:w-[30%] lg:w-[20%] ">
                <input
                  type="text"
                  placeholder="Search by Index Number"
                  className="w-full border border-[#1BA147] px-3 py-2 focus:outline-none focus:ring-2 focus:ring-green-500 bg-[#FFFFFF] placeholder-italic no-spinner lg:ml-36"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>

            <div className="flex mb-6 h-6">
              <p className={`h6 text-red-500 text-sm font-semibold text-center ${errorMessage ? '' : 'invisible'}`}>
                {errorMessage}
              </p>
            </div>

            <div className="flex justify-end mt-4">
              <button
                type="submit"
                className="bg-green-600 text-white font-semibold py-2 px-4 rounded hover:bg-green-700 transition duration-300 border border-[#1BA147]"
              >
                Add Student
              </button>
            </div>
          </form>

          <div className="overflow-x-auto mt-6">
            {existingStudents.length > 0 && (
              <table className="bg-white border-collapse border border-gray-300 mt-4 mx-auto w-full sm:w-3/4 md:w-1/2 lg:w-1/2" style={{ width: '100%' }}>
                <thead>
                  <tr className="bg-white">
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '30%' }}>
                      Student Name
                    </th>
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '25%' }}>
                      Index Number
                    </th>
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '30%' }}>
                      School Name
                    </th>
                    <th className="py-3 px-6 text-center text-sm font-semibold text-gray-700 border border-gray-300 bg-green-10" style={{ width: '15%' }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedStudents.map((student, index) => (
                    <tr key={index} className="bg-white">
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>
                        {student.name?.length > 30 ? student.name.slice(0, 30) + '...' : student.name}
                      </td>
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '25%' }}>
                        {student?.indexNumber}
                      </td>
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>
                        {student.school?.length > 30 ? student.school.slice(0, 30) + '...' : student.school}
                      </td>
                      <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '15%' }}>
                        <a href={`/EditStudent?name=${student?.name}&school=${student?.school}&index=${student?.indexNumber}`} className="text-blue-500 hover:underline">
                          Edit &nbsp;
                        </a>
                        <a href="#" onClick={() => handleDelete(student?.indexNumber)} className="text-red-500 hover:underline">
                          Delete
                        </a>
                      </td>
                    </tr>
                  ))}

                  {paginatedStudents.length < rowsPerPage &&
                    [...Array(rowsPerPage - paginatedStudents.length)].map((_, emptyRowIdx) => (
                      <tr key={`empty-${emptyRowIdx}`} className="bg-white">
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>&nbsp;</td>
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '25%' }}>&nbsp;</td>
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '30%' }}>&nbsp;</td>
                        <td className="table-cell text-center border border-gray-300 py-2" style={{ width: '15%' }}>&nbsp;</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>

          {/* pagination */}

          {existingStudents.length > 0 && (
            <div className="flex justify-center mt-4 w-full">
              <div className="inline-flex overflow-hidden rounded-lg border border-gray-300">
                <button
                  className={`px-3 py-1 ${currentPage === 1 ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  &lt;
                </button>
                {paginationButtons.map((page) => (
                  <button
                    key={page}
                    className={`px-3 py-1 ${currentPage === page ? 'bg-green-600 text-white' : 'bg-white hover:bg-gray-100'}`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                ))}
                <button
                  className={`px-3 py-1 ${currentPage === totalPages ? 'bg-gray-300' : 'bg-white hover:bg-gray-100'}`}
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  &gt;
                </button>
              </div>
            </div>
          )}
        </div>
      </main>

      {showModal && (
        <PopUpModal message={modalMessage} closeModal={() => setShowModal(false)} />
      )}

      {showDeleteModal && (
        <DeleteConfirmationStudentModal
          indexNumber={studentToDelete}
          onConfirm={confirmDelete}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default AddStudent;
