import './App.css';
import LoginPage from './components/login/login';
import Dashboard from './components/dashboard/dashboard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AddMarks from './components/addMarks/addMarks';
import RouteProtection from './firebase/RouteProtection';
import { AuthProvider } from './context/authContext';
import UpdateMarks from './components/updateMarks/updateMarks';
import AddMarksProcess from './components/addMarks/addMarksProcess';
import UpdateMarksProcess from './components/updateMarks/updateMarksProcess';
import StudentResult from './components/home/StudentResult';
import Settings from './components/settings/settings';
import AddTute from './components/settings/addTute';
import Home from './components/home/home';
import AddStudent from './components/settings/addStudent';
import EditTute from './components/editTute/editTute';
import EditStudent from './components/editStudent/editStudent';
import ContactUs from './components/contactUs/contactUs';
import AboutUs from './components/aboutUs/aboutUs';
import TimeTable from './components/timeTable/timeTable';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/65fg4654bfdf48fdsjxkmm-Login' element={<LoginPage />} />
            <Route path='/Dashboard' element={<RouteProtection><Dashboard /></RouteProtection>} />
            <Route path='/AddMarks' element={<RouteProtection><AddMarks /></RouteProtection>} />
            <Route path='/UpdateMarks' element={<RouteProtection><UpdateMarks /></RouteProtection>} />
            <Route path='/AddMarksProcess' element={<RouteProtection><AddMarksProcess /></RouteProtection>} />
            <Route path='/UpdateMarksProcess' element={<RouteProtection><UpdateMarksProcess /></RouteProtection>} />
            <Route path='/StudentResult' element={<StudentResult />} />
            <Route path='/Settings' element={<RouteProtection><Settings /></RouteProtection>} />
            <Route path='/AddTute' element={<RouteProtection><AddTute /></RouteProtection>} />
            <Route path='/AddStudent' element={<RouteProtection><AddStudent /></RouteProtection>} />
            <Route path='/EditTute' element={<RouteProtection><EditTute /></RouteProtection>} />
            <Route path='/EditStudent' element={<RouteProtection><EditStudent /></RouteProtection>} />
            <Route path='/ContactUs' element={<ContactUs />}/>
            <Route path='/AboutUs' element={<AboutUs />} />
            <Route path='/TimeTable' element={<TimeTable />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
